<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("Client") }}</h3>
      </div>
    </div>
    <div class="card-body detail">
      <b-container v-if="loading">
        {{ $t("loading...") }}
      </b-container>
      <template v-else>
        <b-row v-if="client">
          <b-col cols="12">
            <b-row>
              <b-col cols="12" class="font-weight-bold d-flex justify-content-center">
                <div class="d-flex flex-column align-items-center pb-5">
                  <avatar :avatar-image="client.avatar" :avatar-text="client.name[0]" size="100px"></avatar>
                  <span class="h2 text-capitalize">{{ client.name }}</span>
                </div>
              </b-col>
              <b-col cols="12" class="font-weight-bold d-flex align-items-center pb-5">
                <span class="pr-3 form-label">{{ $t("Allow Notification") }}:</span>
                <b-form-checkbox v-model="client.notify" disabled switch size="lg"></b-form-checkbox>
              </b-col>
              <b-col cols="12" class="font-weight-bold d-flex align-items-baseline pb-5">
                <span class="pr-3 form-label">{{ $t("Address") }}:</span>
                <span>{{ client.address_display }}</span>
              </b-col>
              <b-col cols="12" class="font-weight-bold d-flex align-items-baseline pb-5">
                <span v-if="client.registered_office">
                  <span class="pr-3 form-label">{{ $t("Registered office") }}:</span>
                  <span v-for="office in client.registered_office" :key="office.address.id">
                    {{ office.address.formatted }}
                  </span>
                </span>
              </b-col>
              <div v-if="client.vat">
                <b-col cols="12" class="font-weight-bold d-flex align-items-center pb-5">
                  <span class="pr-3 form-label">{{ $t("Vat") }}:</span>
                  <span v-for="[countryIso, vat] in Object.entries(client.vat)" :key="vat" class="pr-4">
                    <CountryFlag :country-iso="countryIso" class="px-1"></CountryFlag>
                    {{ vat }}
                  </span>
                </b-col>
              </div>

              <b-col cols="12" justify-content="center">
                <risk-map-date-alert :date="currentDate"></risk-map-date-alert>
              </b-col>

              <location-risk-provider v-slot="{ isLoading, locationMaxRiskCountries }" :location="locationRiskProvider">
                <b-col cols="12" class="font-weight-bold d-flex align-items-baseline pb-5">
                  <span class="pr-3 form-label">{{ $t("Risk") }}:</span>
                  <b-overlay :show="isLoading" rounded="sm">
                    <div v-if="locationMaxRiskCountries.length" class="d-flex flex-wrap">
                      <rectangle v-for="(risk, idx) in locationMaxRiskCountries" :key="idx" v-b-tooltip.hover
                        :label="countriesMap[risk.country].name" :color="riskLevelLegend[risk.value]?.color" class="m-1">
                      </rectangle>
                    </div>
                  </b-overlay>
                </b-col>
              </location-risk-provider>
            </b-row>
          </b-col>
          <b-col cols="12" class="d-flex align-items-center mb-8">
            <address-map class="border" :lat="client.address.latitude" :lng="client.address.longitude"
              :style="{ width: '100%' }"></address-map>
          </b-col>
          <!-- Fake ChatGPT -->
          <!-- <b-col v-if="country == 'MX'" cols="12">
              <div class="separator separator-dashed my-11"></div>
              <p class="small">* This summary is generated by chatgpt from the report data about the client location, does
                not aim to replace the report.</p>
              <div class="rounded text-white-50 p-8 mt-8" style="background-color: #343641">
                <div class="d-flex align-items-center justify-content-between pb-6">
                  <div>
                    <span class="font-weight-bold h4">Place Description</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="mr-3">Powered by ChatGPT <span
                        class="label label-warning label-inline font-weight-bolder">Plus
                        API</span></span>
                    <img src="/media/logos/ChatGPT_logo.svg.png" height="20" />
                  </div>
                </div>
                <p><strong>San antonio 101, san andres, mexico</strong> is a popular tourist destination in Mexico. It is
                  known for its natural beauty, such as mountains,
                  waterfalls, and beaches, and offers a variety of recreational activities, such as hiking, rafting,
                  canoeing,
                  fishing, horseback riding, skiing, and snowboarding. Accommodations range from hotels, resorts, hostels,
                  vacation homes, and campsites, and there are also many restaurants, bars, cafes, and shops. The city is
                  well-served by public transportation, such as buses, taxis, and trains.
                  San Antonio 101 also has a hospital located in San Andres, 38010 Celaya, Gto., Mexico. It offers 24-hour
                  emergency medical services, vaccinations, and other medical services. The staff speaks English and
                  Japanese.
                  The hospital has 69 beds and provides emergency care and outpatient services to residents of the
                  Guanajuato
                  region.</p>
                <p>When travelling to San Antonio 101, it is important to be aware of potential risks, such as theft and
                  attacks on public transportation, mosquito-borne illnesses, gastrointestinal and respiratory infections,
                  altitude sickness, infectious intestinal diseases, scorpions, and malaria. It is recommended to take
                  preventive measures against mosquito bites, consult a doctor before travelling, and purchase travel
                  insurance. For a safe and healthy trip abroad, it is advisable to drink bottled mineral water or soft
                  drinks, be careful about what you eat, check for any restrictions on importing and exporting
                  pharmaceutical
                  products, and purchase travel insurance.</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="separator separator-dashed my-10"></div>
            </b-col> -->

          <b-col cols="12" class="pt-6">
            <risk-history-table v-if="client.address.latitude && client.address.longitude" :lat="client.address.latitude"
              :lng="client.address.longitude" :date-start="dateStart" :date-end="dateEnd" :country="country"
              :risk-logs="client.risk_logs"></risk-history-table>
          </b-col>
        </b-row>
      </template>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AddressMap from "@/view/components/AddressMap.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import LocationRiskProvider from "@/view/components/providers/LocationRiskProvider.vue";
import RiskService from "@/core/services/risk/risk.service";
import DateService from "@/core/services/date.service";
import Rectangle from "@/view/components/shapes/Rectangle.vue";
import icons from "@/core/config/icons";
import RiskHistoryTable from "@/view/components/tables/RiskHistoryTable.vue";
import RiskMapDateAlert from "@/view/components/alerts/RiskMapDateAlert.vue";
export default {
  components: {
    AddressMap,
    LocationRiskProvider,
    Rectangle,
    RiskHistoryTable,
    RiskMapDateAlert,
    CountryFlag
  },

  props: {
    client: {
      type: Object,
      default: null,
    },
    isIntragroupWork: {
      type: Boolean,
      default: false,
    },
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: "",
    },
    service: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icons,
      loading: false,
      localActor: null,
      riskLevelLegend: RiskService.RISK_LEGEND_MAP,
    };
  },

  computed: {
    ...mapState("constants", ["countries", "physicalAddressKinds", "companyAddressKinds"]),
    ...mapGetters("user", ["dateFormat"]),
    ...mapGetters("constants", ["countriesMap"]),

    currentDate() {
      return DateService.format(new Date(), this.dateFormat);
    },
    locationRiskProvider() {
      return {
        ...this.client.address,
        country: this.client?.address?.locality?.state?.country.code ?? "",
      };
    },
  },

  watch: {
    client: {
      deep: true,
      handler() {
        this.resetClient();
      },
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments")},
      { title: this.$t("Client") },
    ]);
    await this.resetClient();
  },

  methods: {

    async resetClient() {
      if (!this.client) return;
      // eslint-disable-next-line no-unused-vars
      const { activities, ...newClient } = this.client;
      this.localActor = _.cloneDeep(newClient);
    },

  },

};
</script>

<style scoped>
.form-label {
  font-size: 1rem;
  width: 14ch;
}
</style>
