<template>
  <div>
    <slot :isLoading="isLoading" :locationMaxRisk="locationMaxRisk" :locationMaxRiskCountries="locationMaxRiskCountries">
    </slot>
  </div>
</template>

<script>
import RiskService from "@/core/services/risk/risk.service";
export default {
  props: {
    location: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    isLoading: false,
    response: null,
  }),

  computed: {
    locationMaxRisk() {
      return this.response?.risk ?? 0;
    },

    locationMaxRiskCountries() {
      if (!this.response?.risk_status) {
        return [];
      }

      return Object.keys(this.response?.risk_status).reduce((acc, key) => {
        const value = this.response?.risk_status[key];
        acc.push({ country: key, value: value });
        return acc;
      }, []);
    },
  },

  watch: {
    location: {
      deep: true,
      immediate: true,
      handler() {
        this.loadRisk();
      },
    },
  },

  methods: {
    async loadRisk() {
      if (!this.location) return;
      const country = this.location.country;
      const lat = this.location.latitude;
      const lng = this.location.longitude;
      if (!country || !lat || !lng) return;

      this.isLoading = true;

      try {
        this.response = await RiskService.getPlaceRiskLevel(country, lat, lng);
      } catch (err) {
        console.error(err);
        this.response = null;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
