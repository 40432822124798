<template>
  <datatable :table-props="tableProps" :options.sync="tableOptions">
    <template #topbar>
      <h3 class="text-center">{{ $t('Risk history') }}</h3>
    </template>

    <template #[`cell.risks`]="{ item }">
      <div class="d-flex flex-wrap gap-1">
        <rectangle :label="riskLevelLegend[item.risk_status.risk].label"
          :color="riskLevelLegend[item.risk_status.risk].color" class="mr-12"></rectangle>
        <rectangle v-for="[iso, value] in Object.entries(item.risk_status.risk_status)" :key="iso" v-b-tooltip.hover
          :label="countriesMap[iso].name" :color="riskLevelLegend[value]?.color" class="mr-1"></rectangle>
      </div>
    </template>

    <template #[`cell.at_date`]="{ item }">
      {{ DateService.format(item.at_date, dateFormat) }}
    </template>
  </datatable>
</template>

<script>
import DateService from "@/core/services/date.service";
import RiskService from "@/core/services/risk/risk.service";
import Rectangle from "@/view/components/shapes/Rectangle.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Rectangle,
  },

  props: {
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    lat: {
      type: [String, Number],
      required: true,
    },
    lng: {
      type: [String, Number],
      required: true,
    },
    riskLogs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      tableOptions: {
        "sort-by": "at_date",
        "sort-desc": true,
        "per-page": 10,
        "current-page": 1,
      },
      isLoading: false,
      riskLevelLegend: RiskService.RISK_LEGEND_MAP,
      DateService,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),
    ...mapGetters("constants", ["countriesMap"]),

    tableProps() {
      return {
        items: this.riskLogs,
        fields: [
          { label: this.$t('At date'), key: "at_date", class: ["font-weight-bolder"] },
          { label: this.$t('Risks'), key: "risks" },
        ],
        busy: this.isLoading,
      };
    },
  },
};
</script>
