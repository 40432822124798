var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.lat && _vm.lng ? _c('div', {
    style: "height: ".concat(_vm.height, "px")
  }, [_c('google-maps-map', {
    attrs: {
      "map-options": {
        center: _vm.center,
        zoom: 15
      },
      "markers": [{
        position: _vm.center
      }],
      "height": "100%"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center",
    style: "height: ".concat(_vm.height, "px")
  }, [_c('i', {
    staticClass: "fas fa-exclamation-triangle text-danger icon-xxl mb-2"
  }), _c('p', {
    staticClass: "font-weight-bolder h4"
  }, [_vm._v(" Something went wrong! ")]), _c('p', {
    staticClass: "font-weight-bolder h4"
  }, [_vm._v(" We either couldn't find the location on google maps or you have to select an address. ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }