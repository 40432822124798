var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('datatable', {
    attrs: {
      "table-props": _vm.tableProps,
      "options": _vm.tableOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "topbar",
      fn: function fn() {
        return [_c('h3', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s(_vm.$t('Risk history')))])];
      },
      proxy: true
    }, {
      key: "cell.risks",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex flex-wrap gap-1"
        }, [_c('rectangle', {
          staticClass: "mr-12",
          attrs: {
            "label": _vm.riskLevelLegend[item.risk_status.risk].label,
            "color": _vm.riskLevelLegend[item.risk_status.risk].color
          }
        }), _vm._l(Object.entries(item.risk_status.risk_status), function (_ref2) {
          var _vm$riskLevelLegend$v;
          var iso = _ref2[0],
            value = _ref2[1];
          return _c('rectangle', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            key: iso,
            staticClass: "mr-1",
            attrs: {
              "label": _vm.countriesMap[iso].name,
              "color": (_vm$riskLevelLegend$v = _vm.riskLevelLegend[value]) === null || _vm$riskLevelLegend$v === void 0 ? void 0 : _vm$riskLevelLegend$v.color
            }
          });
        })], 2)];
      }
    }, {
      key: "cell.at_date",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.DateService.format(item.at_date, _vm.dateFormat)) + " ")];
      }
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }