var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Client")))])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_vm.loading ? _c('b-container', [_vm._v(" " + _vm._s(_vm.$t("loading...")) + " ")]) : [_vm.client ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold d-flex justify-content-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center pb-5"
  }, [_c('avatar', {
    attrs: {
      "avatar-image": _vm.client.avatar,
      "avatar-text": _vm.client.name[0],
      "size": "100px"
    }
  }), _c('span', {
    staticClass: "h2 text-capitalize"
  }, [_vm._v(_vm._s(_vm.client.name))])], 1)]), _c('b-col', {
    staticClass: "font-weight-bold d-flex align-items-center pb-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Allow Notification")) + ":")]), _c('b-form-checkbox', {
    attrs: {
      "disabled": "",
      "switch": "",
      "size": "lg"
    },
    model: {
      value: _vm.client.notify,
      callback: function callback($$v) {
        _vm.$set(_vm.client, "notify", $$v);
      },
      expression: "client.notify"
    }
  })], 1), _c('b-col', {
    staticClass: "font-weight-bold d-flex align-items-baseline pb-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Address")) + ":")]), _c('span', [_vm._v(_vm._s(_vm.client.address_display))])]), _c('b-col', {
    staticClass: "font-weight-bold d-flex align-items-baseline pb-5",
    attrs: {
      "cols": "12"
    }
  }, [_vm.client.registered_office ? _c('span', [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Registered office")) + ":")]), _vm._l(_vm.client.registered_office, function (office) {
    return _c('span', {
      key: office.address.id
    }, [_vm._v(" " + _vm._s(office.address.formatted) + " ")]);
  })], 2) : _vm._e()]), _vm.client.vat ? _c('div', [_c('b-col', {
    staticClass: "font-weight-bold d-flex align-items-center pb-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Vat")) + ":")]), _vm._l(Object.entries(_vm.client.vat), function (_ref) {
    var countryIso = _ref[0],
      vat = _ref[1];
    return _c('span', {
      key: vat,
      staticClass: "pr-4"
    }, [_c('CountryFlag', {
      staticClass: "px-1",
      attrs: {
        "country-iso": countryIso
      }
    }), _vm._v(" " + _vm._s(vat) + " ")], 1);
  })], 2)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12",
      "justify-content": "center"
    }
  }, [_c('risk-map-date-alert', {
    attrs: {
      "date": _vm.currentDate
    }
  })], 1), _c('location-risk-provider', {
    attrs: {
      "location": _vm.locationRiskProvider
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var isLoading = _ref2.isLoading,
          locationMaxRiskCountries = _ref2.locationMaxRiskCountries;
        return [_c('b-col', {
          staticClass: "font-weight-bold d-flex align-items-baseline pb-5",
          attrs: {
            "cols": "12"
          }
        }, [_c('span', {
          staticClass: "pr-3 form-label"
        }, [_vm._v(_vm._s(_vm.$t("Risk")) + ":")]), _c('b-overlay', {
          attrs: {
            "show": isLoading,
            "rounded": "sm"
          }
        }, [locationMaxRiskCountries.length ? _c('div', {
          staticClass: "d-flex flex-wrap"
        }, _vm._l(locationMaxRiskCountries, function (risk, idx) {
          var _vm$riskLevelLegend$r;
          return _c('rectangle', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            key: idx,
            staticClass: "m-1",
            attrs: {
              "label": _vm.countriesMap[risk.country].name,
              "color": (_vm$riskLevelLegend$r = _vm.riskLevelLegend[risk.value]) === null || _vm$riskLevelLegend$r === void 0 ? void 0 : _vm$riskLevelLegend$r.color
            }
          });
        }), 1) : _vm._e()])], 1)];
      }
    }], null, false, 3583551886)
  })], 1)], 1), _c('b-col', {
    staticClass: "d-flex align-items-center mb-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('address-map', {
    staticClass: "border",
    style: {
      width: '100%'
    },
    attrs: {
      "lat": _vm.client.address.latitude,
      "lng": _vm.client.address.longitude
    }
  })], 1), _c('b-col', {
    staticClass: "pt-6",
    attrs: {
      "cols": "12"
    }
  }, [_vm.client.address.latitude && _vm.client.address.longitude ? _c('risk-history-table', {
    attrs: {
      "lat": _vm.client.address.latitude,
      "lng": _vm.client.address.longitude,
      "date-start": _vm.dateStart,
      "date-end": _vm.dateEnd,
      "country": _vm.country,
      "risk-logs": _vm.client.risk_logs
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }