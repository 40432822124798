<template>
  <div>
    <div v-if="lat && lng" :style="`height: ${height}px`">
      <google-maps-map
        :map-options="{
          center,
          zoom: 15,
        }"
        :markers="[{ position: center }]"
        height="100%"
      ></google-maps-map>
    </div>
    <div v-else :style="`height: ${height}px`" class="d-flex flex-column justify-content-center align-items-center">
      <i class="fas fa-exclamation-triangle text-danger icon-xxl mb-2"></i>
      <p class="font-weight-bolder h4">
        Something went wrong!
      </p>
      <p class="font-weight-bolder h4">
        We either couldn't find the location on google maps or you have to select an address.
      </p>
    </div>
  </div>
</template>

<script>
import GoogleMapsMap from "@/view/components/google-maps/GoogleMapsMap.vue";
export default {
  components: {
    GoogleMapsMap,
  },

  props: {
    lat: {
      type: [Number, String],
      default: null,
    },
    lng: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: 240,
    },
  },

  computed: {
    center() {
      return {
        lat: this.lat,
        lng: this.lng,
      };
    },
  },
};
</script>
